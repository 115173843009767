import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindProductByIdForStoreV2QueryVariables = Types.Exact<{
  input: Types.FindProductByIdForStoreInput;
}>;


export type FindProductByIdForStoreV2Query = { __typename?: 'Query', findProductByIdForStoreV2: { __typename?: 'ListingProductDetailV2', id: number, name: string, image: string, brand: string, description?: string | null, notification: boolean, promotions?: Array<{ __typename?: 'PromotionOnListingProductDetail', id: number, type: Types.PromotionType, pillInfo?: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null } | null } | null> | null, images: Array<{ __typename?: 'ProductImage', id: number, image: string, default: boolean } | null>, category: { __typename?: 'ListingCategory', id: number, name: string, image?: string | null }, displays: Array<{ __typename?: 'ListingDisplayV2', id: number, default: boolean, units: number, storeUnavailabiliy: boolean, price: { __typename?: 'Money', amount: number, currency: string, scale: number }, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number } }> } };


export const FindProductByIdForStoreV2Document = gql`
    query FindProductByIdForStoreV2($input: FindProductByIdForStoreInput!) {
  findProductByIdForStoreV2(input: $input) {
    id
    name
    image
    promotions {
      id
      type
      pillInfo {
        pillType
        text
        promotionId
      }
    }
    images {
      id
      image
      default
    }
    brand
    category {
      id
      name
      image
    }
    description
    notification
    displays {
      id
      default
      units
      price {
        amount
        currency
        scale
      }
      unitPrice {
        amount
        currency
        scale
      }
      storeUnavailabiliy
    }
  }
}
    `;

/**
 * __useFindProductByIdForStoreV2Query__
 *
 * To run a query within a React component, call `useFindProductByIdForStoreV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindProductByIdForStoreV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductByIdForStoreV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindProductByIdForStoreV2Query(baseOptions: Apollo.QueryHookOptions<FindProductByIdForStoreV2Query, FindProductByIdForStoreV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductByIdForStoreV2Query, FindProductByIdForStoreV2QueryVariables>(FindProductByIdForStoreV2Document, options);
      }
export function useFindProductByIdForStoreV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductByIdForStoreV2Query, FindProductByIdForStoreV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductByIdForStoreV2Query, FindProductByIdForStoreV2QueryVariables>(FindProductByIdForStoreV2Document, options);
        }
export type FindProductByIdForStoreV2QueryHookResult = ReturnType<typeof useFindProductByIdForStoreV2Query>;
export type FindProductByIdForStoreV2LazyQueryHookResult = ReturnType<typeof useFindProductByIdForStoreV2LazyQuery>;
export type FindProductByIdForStoreV2QueryResult = Apollo.QueryResult<FindProductByIdForStoreV2Query, FindProductByIdForStoreV2QueryVariables>;