import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindParentCategoriesFromSupplierQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindParentCategoriesFromSupplierQuery = { __typename?: 'Query', findParentCategoriesFromSupplier: Array<{ __typename?: 'Category', id: number, name: string, internalCode?: string | null, enabled: boolean, supplierId: number, image?: string | null, parentId?: number | null }> };

export type FindChildCategoriesQueryVariables = Types.Exact<{
  parentId: Types.Scalars['Int'];
}>;


export type FindChildCategoriesQuery = { __typename?: 'Query', findChildCategories: Array<{ __typename?: 'Category', id: number, name: string, internalCode?: string | null, enabled: boolean, supplierId: number, image?: string | null, parentId?: number | null }> };

export type CreateCategoryMutationVariables = Types.Exact<{
  input: Types.CreateCategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory: { __typename?: 'MutationCategoryResponse', category?: { __typename?: 'Category', id: number, name: string, internalCode?: string | null, enabled: boolean, supplierId: number, image?: string | null, parentId?: number | null } | null } };

export type UpdateCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory: { __typename?: 'MutationCategoryResponse', category?: { __typename?: 'Category', id: number, name: string, internalCode?: string | null, enabled: boolean, supplierId: number, image?: string | null, parentId?: number | null } | null } };

export type DeleteCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteCategory: { __typename?: 'MutationCategoryResponse', category?: { __typename?: 'Category', id: number, name: string, internalCode?: string | null, enabled: boolean, supplierId: number, image?: string | null, parentId?: number | null } | null } };

export type GetUrlForCategoryImageUploadQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type GetUrlForCategoryImageUploadQuery = { __typename?: 'Query', getUrlForCategoryImageUpload?: { __typename?: 'GetUrlForCategoryImageUploadResponse', url: string, key: string } | null };


export const FindParentCategoriesFromSupplierDocument = gql`
    query FindParentCategoriesFromSupplier {
  findParentCategoriesFromSupplier {
    id
    name
    internalCode
    enabled
    supplierId
    image
    parentId
  }
}
    `;

/**
 * __useFindParentCategoriesFromSupplierQuery__
 *
 * To run a query within a React component, call `useFindParentCategoriesFromSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindParentCategoriesFromSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindParentCategoriesFromSupplierQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindParentCategoriesFromSupplierQuery(baseOptions?: Apollo.QueryHookOptions<FindParentCategoriesFromSupplierQuery, FindParentCategoriesFromSupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindParentCategoriesFromSupplierQuery, FindParentCategoriesFromSupplierQueryVariables>(FindParentCategoriesFromSupplierDocument, options);
      }
export function useFindParentCategoriesFromSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindParentCategoriesFromSupplierQuery, FindParentCategoriesFromSupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindParentCategoriesFromSupplierQuery, FindParentCategoriesFromSupplierQueryVariables>(FindParentCategoriesFromSupplierDocument, options);
        }
export type FindParentCategoriesFromSupplierQueryHookResult = ReturnType<typeof useFindParentCategoriesFromSupplierQuery>;
export type FindParentCategoriesFromSupplierLazyQueryHookResult = ReturnType<typeof useFindParentCategoriesFromSupplierLazyQuery>;
export type FindParentCategoriesFromSupplierQueryResult = Apollo.QueryResult<FindParentCategoriesFromSupplierQuery, FindParentCategoriesFromSupplierQueryVariables>;
export const FindChildCategoriesDocument = gql`
    query FindChildCategories($parentId: Int!) {
  findChildCategories(parentId: $parentId) {
    id
    name
    internalCode
    enabled
    supplierId
    image
    parentId
  }
}
    `;

/**
 * __useFindChildCategoriesQuery__
 *
 * To run a query within a React component, call `useFindChildCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChildCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChildCategoriesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useFindChildCategoriesQuery(baseOptions: Apollo.QueryHookOptions<FindChildCategoriesQuery, FindChildCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindChildCategoriesQuery, FindChildCategoriesQueryVariables>(FindChildCategoriesDocument, options);
      }
export function useFindChildCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindChildCategoriesQuery, FindChildCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindChildCategoriesQuery, FindChildCategoriesQueryVariables>(FindChildCategoriesDocument, options);
        }
export type FindChildCategoriesQueryHookResult = ReturnType<typeof useFindChildCategoriesQuery>;
export type FindChildCategoriesLazyQueryHookResult = ReturnType<typeof useFindChildCategoriesLazyQuery>;
export type FindChildCategoriesQueryResult = Apollo.QueryResult<FindChildCategoriesQuery, FindChildCategoriesQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(createCategoryInput: $input) {
    category {
      id
      name
      internalCode
      enabled
      supplierId
      image
      parentId
    }
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(updateCategoryInput: $input) {
    category {
      id
      name
      internalCode
      enabled
      supplierId
      image
      parentId
    }
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: Int!) {
  deleteCategory(id: $id) {
    category {
      id
      name
      internalCode
      enabled
      supplierId
      image
      parentId
    }
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const GetUrlForCategoryImageUploadDocument = gql`
    query GetUrlForCategoryImageUpload($name: String!) {
  getUrlForCategoryImageUpload(name: $name) {
    url
    key
  }
}
    `;

/**
 * __useGetUrlForCategoryImageUploadQuery__
 *
 * To run a query within a React component, call `useGetUrlForCategoryImageUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlForCategoryImageUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlForCategoryImageUploadQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetUrlForCategoryImageUploadQuery(baseOptions: Apollo.QueryHookOptions<GetUrlForCategoryImageUploadQuery, GetUrlForCategoryImageUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlForCategoryImageUploadQuery, GetUrlForCategoryImageUploadQueryVariables>(GetUrlForCategoryImageUploadDocument, options);
      }
export function useGetUrlForCategoryImageUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlForCategoryImageUploadQuery, GetUrlForCategoryImageUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlForCategoryImageUploadQuery, GetUrlForCategoryImageUploadQueryVariables>(GetUrlForCategoryImageUploadDocument, options);
        }
export type GetUrlForCategoryImageUploadQueryHookResult = ReturnType<typeof useGetUrlForCategoryImageUploadQuery>;
export type GetUrlForCategoryImageUploadLazyQueryHookResult = ReturnType<typeof useGetUrlForCategoryImageUploadLazyQuery>;
export type GetUrlForCategoryImageUploadQueryResult = Apollo.QueryResult<GetUrlForCategoryImageUploadQuery, GetUrlForCategoryImageUploadQueryVariables>;