import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindBrandsQueryVariables = Types.Exact<{
  findBrandsInput: Types.FindBrandsInput;
}>;


export type FindBrandsQuery = { __typename?: 'Query', findBrands: { __typename?: 'PagedBrand', brands: Array<{ __typename?: 'Brand', id: number, name: string, internalCode?: string | null, logo: string, enabled: boolean, supplier?: { __typename?: 'Supplier', id: number, name: string, avatar?: string | null, type: Types.SupplierType } | null } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindAllBrandsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindAllBrandsQuery = { __typename?: 'Query', findAllBrands: Array<{ __typename?: 'Brand', id: number, name: string, internalCode?: string | null, logo: string, enabled: boolean, supplier?: { __typename?: 'Supplier', id: number } | null } | null> };

export type GetUrlForBrandLogoUploadQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type GetUrlForBrandLogoUploadQuery = { __typename?: 'Query', getUrlForBrandLogoUpload?: { __typename?: 'GetUrlForBrandLogoUploadResponse', url: string, key: string } | null };

export type CreateBrandMutationVariables = Types.Exact<{
  createBrandInput: Types.CreateBrandInput;
}>;


export type CreateBrandMutation = { __typename?: 'Mutation', createBrand: { __typename?: 'MutationBrandResponse', brand?: { __typename?: 'Brand', id: number, internalCode?: string | null, name: string, logo: string, enabled: boolean } | null } };

export type UpdateBrandMutationVariables = Types.Exact<{
  updateBrandInput: Types.UpdateBrandInput;
}>;


export type UpdateBrandMutation = { __typename?: 'Mutation', updateBrand: { __typename?: 'MutationBrandResponse', brand?: { __typename?: 'Brand', id: number, internalCode?: string | null, name: string, logo: string, supplier?: { __typename?: 'Supplier', id: number } | null } | null } };


export const FindBrandsDocument = gql`
    query FindBrands($findBrandsInput: FindBrandsInput!) {
  findBrands(findBrandsInput: $findBrandsInput) {
    brands {
      id
      name
      internalCode
      logo
      enabled
      supplier {
        id
        name
        avatar
        type
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindBrandsQuery__
 *
 * To run a query within a React component, call `useFindBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBrandsQuery({
 *   variables: {
 *      findBrandsInput: // value for 'findBrandsInput'
 *   },
 * });
 */
export function useFindBrandsQuery(baseOptions: Apollo.QueryHookOptions<FindBrandsQuery, FindBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindBrandsQuery, FindBrandsQueryVariables>(FindBrandsDocument, options);
      }
export function useFindBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindBrandsQuery, FindBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindBrandsQuery, FindBrandsQueryVariables>(FindBrandsDocument, options);
        }
export type FindBrandsQueryHookResult = ReturnType<typeof useFindBrandsQuery>;
export type FindBrandsLazyQueryHookResult = ReturnType<typeof useFindBrandsLazyQuery>;
export type FindBrandsQueryResult = Apollo.QueryResult<FindBrandsQuery, FindBrandsQueryVariables>;
export const FindAllBrandsDocument = gql`
    query FindAllBrands {
  findAllBrands {
    id
    name
    internalCode
    logo
    supplier {
      id
    }
    enabled
  }
}
    `;

/**
 * __useFindAllBrandsQuery__
 *
 * To run a query within a React component, call `useFindAllBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllBrandsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllBrandsQuery, FindAllBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllBrandsQuery, FindAllBrandsQueryVariables>(FindAllBrandsDocument, options);
      }
export function useFindAllBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllBrandsQuery, FindAllBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllBrandsQuery, FindAllBrandsQueryVariables>(FindAllBrandsDocument, options);
        }
export type FindAllBrandsQueryHookResult = ReturnType<typeof useFindAllBrandsQuery>;
export type FindAllBrandsLazyQueryHookResult = ReturnType<typeof useFindAllBrandsLazyQuery>;
export type FindAllBrandsQueryResult = Apollo.QueryResult<FindAllBrandsQuery, FindAllBrandsQueryVariables>;
export const GetUrlForBrandLogoUploadDocument = gql`
    query GetUrlForBrandLogoUpload($name: String!) {
  getUrlForBrandLogoUpload(name: $name) {
    url
    key
  }
}
    `;

/**
 * __useGetUrlForBrandLogoUploadQuery__
 *
 * To run a query within a React component, call `useGetUrlForBrandLogoUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlForBrandLogoUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlForBrandLogoUploadQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetUrlForBrandLogoUploadQuery(baseOptions: Apollo.QueryHookOptions<GetUrlForBrandLogoUploadQuery, GetUrlForBrandLogoUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlForBrandLogoUploadQuery, GetUrlForBrandLogoUploadQueryVariables>(GetUrlForBrandLogoUploadDocument, options);
      }
export function useGetUrlForBrandLogoUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlForBrandLogoUploadQuery, GetUrlForBrandLogoUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlForBrandLogoUploadQuery, GetUrlForBrandLogoUploadQueryVariables>(GetUrlForBrandLogoUploadDocument, options);
        }
export type GetUrlForBrandLogoUploadQueryHookResult = ReturnType<typeof useGetUrlForBrandLogoUploadQuery>;
export type GetUrlForBrandLogoUploadLazyQueryHookResult = ReturnType<typeof useGetUrlForBrandLogoUploadLazyQuery>;
export type GetUrlForBrandLogoUploadQueryResult = Apollo.QueryResult<GetUrlForBrandLogoUploadQuery, GetUrlForBrandLogoUploadQueryVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($createBrandInput: CreateBrandInput!) {
  createBrand(createBrandInput: $createBrandInput) {
    brand {
      id
      internalCode
      name
      logo
      enabled
    }
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      createBrandInput: // value for 'createBrandInput'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const UpdateBrandDocument = gql`
    mutation UpdateBrand($updateBrandInput: UpdateBrandInput!) {
  updateBrand(updateBrandInput: $updateBrandInput) {
    brand {
      id
      internalCode
      name
      logo
      supplier {
        id
      }
    }
  }
}
    `;
export type UpdateBrandMutationFn = Apollo.MutationFunction<UpdateBrandMutation, UpdateBrandMutationVariables>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      updateBrandInput: // value for 'updateBrandInput'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandMutation, UpdateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(UpdateBrandDocument, options);
      }
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<UpdateBrandMutation, UpdateBrandMutationVariables>;