import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindCrossedBonusPromotionsForStoreV2QueryVariables = Types.Exact<{
  input: Types.FindPromotionsForStoreByTypeInput;
}>;


export type FindCrossedBonusPromotionsForStoreV2Query = { __typename?: 'Query', findCrossedBonusPromotionsForStoreV2: { __typename?: 'PagedCrossedBonusPromotionV2', promotions: Array<{ __typename?: 'CrossedBonusPromotionV2', id: number, type: Types.PromotionType, title: string, description: string, image: string, pillInfo: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null } }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };


export const FindCrossedBonusPromotionsForStoreV2Document = gql`
    query FindCrossedBonusPromotionsForStoreV2($input: FindPromotionsForStoreByTypeInput!) {
  findCrossedBonusPromotionsForStoreV2(input: $input) {
    promotions {
      id
      type
      pillInfo {
        pillType
        text
        promotionId
      }
      title
      description
      image
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindCrossedBonusPromotionsForStoreV2Query__
 *
 * To run a query within a React component, call `useFindCrossedBonusPromotionsForStoreV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindCrossedBonusPromotionsForStoreV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCrossedBonusPromotionsForStoreV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCrossedBonusPromotionsForStoreV2Query(baseOptions: Apollo.QueryHookOptions<FindCrossedBonusPromotionsForStoreV2Query, FindCrossedBonusPromotionsForStoreV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCrossedBonusPromotionsForStoreV2Query, FindCrossedBonusPromotionsForStoreV2QueryVariables>(FindCrossedBonusPromotionsForStoreV2Document, options);
      }
export function useFindCrossedBonusPromotionsForStoreV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCrossedBonusPromotionsForStoreV2Query, FindCrossedBonusPromotionsForStoreV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCrossedBonusPromotionsForStoreV2Query, FindCrossedBonusPromotionsForStoreV2QueryVariables>(FindCrossedBonusPromotionsForStoreV2Document, options);
        }
export type FindCrossedBonusPromotionsForStoreV2QueryHookResult = ReturnType<typeof useFindCrossedBonusPromotionsForStoreV2Query>;
export type FindCrossedBonusPromotionsForStoreV2LazyQueryHookResult = ReturnType<typeof useFindCrossedBonusPromotionsForStoreV2LazyQuery>;
export type FindCrossedBonusPromotionsForStoreV2QueryResult = Apollo.QueryResult<FindCrossedBonusPromotionsForStoreV2Query, FindCrossedBonusPromotionsForStoreV2QueryVariables>;