import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindMultipleDiscountPromotionsForStoreV2QueryVariables = Types.Exact<{
  input: Types.FindPromotionsForStoreByTypeInput;
}>;


export type FindMultipleDiscountPromotionsForStoreV2Query = { __typename?: 'Query', findMultipleDiscountPromotionsForStoreV2: { __typename?: 'PagedMultipleDiscountPromotionV2', promotions: Array<{ __typename?: 'MultipleDiscountPromotionV2', id: number, type: Types.PromotionType, title: string, description: string, image: string, pillInfo: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null } }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };


export const FindMultipleDiscountPromotionsForStoreV2Document = gql`
    query FindMultipleDiscountPromotionsForStoreV2($input: FindPromotionsForStoreByTypeInput!) {
  findMultipleDiscountPromotionsForStoreV2(input: $input) {
    promotions {
      id
      type
      pillInfo {
        pillType
        text
        promotionId
      }
      title
      description
      image
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindMultipleDiscountPromotionsForStoreV2Query__
 *
 * To run a query within a React component, call `useFindMultipleDiscountPromotionsForStoreV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindMultipleDiscountPromotionsForStoreV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMultipleDiscountPromotionsForStoreV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindMultipleDiscountPromotionsForStoreV2Query(baseOptions: Apollo.QueryHookOptions<FindMultipleDiscountPromotionsForStoreV2Query, FindMultipleDiscountPromotionsForStoreV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMultipleDiscountPromotionsForStoreV2Query, FindMultipleDiscountPromotionsForStoreV2QueryVariables>(FindMultipleDiscountPromotionsForStoreV2Document, options);
      }
export function useFindMultipleDiscountPromotionsForStoreV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMultipleDiscountPromotionsForStoreV2Query, FindMultipleDiscountPromotionsForStoreV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMultipleDiscountPromotionsForStoreV2Query, FindMultipleDiscountPromotionsForStoreV2QueryVariables>(FindMultipleDiscountPromotionsForStoreV2Document, options);
        }
export type FindMultipleDiscountPromotionsForStoreV2QueryHookResult = ReturnType<typeof useFindMultipleDiscountPromotionsForStoreV2Query>;
export type FindMultipleDiscountPromotionsForStoreV2LazyQueryHookResult = ReturnType<typeof useFindMultipleDiscountPromotionsForStoreV2LazyQuery>;
export type FindMultipleDiscountPromotionsForStoreV2QueryResult = Apollo.QueryResult<FindMultipleDiscountPromotionsForStoreV2Query, FindMultipleDiscountPromotionsForStoreV2QueryVariables>;