import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindIndividualBonusPromotionsForStoreV2QueryVariables = Types.Exact<{
  input: Types.FindPromotionsForStoreByTypeInput;
}>;


export type FindIndividualBonusPromotionsForStoreV2Query = { __typename?: 'Query', findIndividualBonusPromotionsForStoreV2: { __typename?: 'PagedIndividualBonusPromotionV2', promotions: Array<{ __typename?: 'IndividualBonusPromotionV2', id: number, type: Types.PromotionType, products: Array<{ __typename?: 'ProductDetailInPromotion', id: number, name: string, description: string, image: string, brand: string, notification: boolean, boxRatio: number, images: Array<{ __typename?: 'ProductImage', id: number, image: string, default: boolean }>, displays: Array<{ __typename?: 'DisplayProductDetailInPromotion', id: number, productId: number, default: boolean, units: number, storeUnavailabiliy: boolean, price: { __typename?: 'Money', amount: number, currency: string, scale: number }, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number } }> }>, pillInfo: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null } }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };


export const FindIndividualBonusPromotionsForStoreV2Document = gql`
    query FindIndividualBonusPromotionsForStoreV2($input: FindPromotionsForStoreByTypeInput!) {
  findIndividualBonusPromotionsForStoreV2(input: $input) {
    promotions {
      id
      type
      products {
        id
        name
        description
        image
        images {
          id
          image
          default
        }
        brand
        notification
        displays {
          id
          productId
          default
          units
          price {
            amount
            currency
            scale
          }
          unitPrice {
            amount
            currency
            scale
          }
          storeUnavailabiliy
        }
        boxRatio
      }
      pillInfo {
        pillType
        text
        promotionId
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindIndividualBonusPromotionsForStoreV2Query__
 *
 * To run a query within a React component, call `useFindIndividualBonusPromotionsForStoreV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindIndividualBonusPromotionsForStoreV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIndividualBonusPromotionsForStoreV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindIndividualBonusPromotionsForStoreV2Query(baseOptions: Apollo.QueryHookOptions<FindIndividualBonusPromotionsForStoreV2Query, FindIndividualBonusPromotionsForStoreV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindIndividualBonusPromotionsForStoreV2Query, FindIndividualBonusPromotionsForStoreV2QueryVariables>(FindIndividualBonusPromotionsForStoreV2Document, options);
      }
export function useFindIndividualBonusPromotionsForStoreV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindIndividualBonusPromotionsForStoreV2Query, FindIndividualBonusPromotionsForStoreV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindIndividualBonusPromotionsForStoreV2Query, FindIndividualBonusPromotionsForStoreV2QueryVariables>(FindIndividualBonusPromotionsForStoreV2Document, options);
        }
export type FindIndividualBonusPromotionsForStoreV2QueryHookResult = ReturnType<typeof useFindIndividualBonusPromotionsForStoreV2Query>;
export type FindIndividualBonusPromotionsForStoreV2LazyQueryHookResult = ReturnType<typeof useFindIndividualBonusPromotionsForStoreV2LazyQuery>;
export type FindIndividualBonusPromotionsForStoreV2QueryResult = Apollo.QueryResult<FindIndividualBonusPromotionsForStoreV2Query, FindIndividualBonusPromotionsForStoreV2QueryVariables>;